<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>

  <div v-else-if="showModuloForm === true">
    <EmailIntegracaos
      :dados="dadosProps.email"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.PAINEL_CLIENTE,
          moduloEnum.ORCAMENTO_WEB,
          moduloEnum.ORCAMENTO_APP,
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <ErpIntegracoes
      :dados="dadosProps.erp"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.PORTFOLIO_WEB,
          moduloEnum.ORCAMENTO_WEB,
          moduloEnum.ORCAMENTO_APP,
          moduloEnum.PAINEL_CLIENTE,
          moduloEnum.VENDAMAIS_WEB,
          moduloEnum.VENDAMAIS_APP,
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <GoogleAnalyticsIntegracoes
      :dados="dadosProps.googleAnalytics"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.PORTFOLIO_WEB,
          moduloEnum.ORCAMENTO_WEB,
          moduloEnum.ORCAMENTO_APP,
          moduloEnum.PAINEL_CLIENTE,
          moduloEnum.VENDAMAIS_WEB,
          moduloEnum.VENDAMAIS_APP,
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <SmsIntegracoes
      :dados="dadosProps.sms"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.PAINEL_CLIENTE,
          moduloEnum.ORCAMENTO_WEB,
          moduloEnum.ORCAMENTO_APP,
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <BlipIntegracoesVue
      :dados="dadosProps.blip"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.PORTFOLIO_WEB,
          moduloEnum.ORCAMENTO_WEB,
          moduloEnum.ORCAMENTO_APP,
          moduloEnum.PAINEL_CLIENTE,
          moduloEnum.VENDAMAIS_WEB,
          moduloEnum.VENDAMAIS_APP,
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <RdStationIntegracoes
      :dados="dadosProps.rdStation"
      @removeOauth="removeOauth('rd-station', 'RD Station Marketing')"
      v-if="
        rdstation &&
          rdstation.config &&
          rdstation.config.url_link_account &&
          showComponente([
            moduloEnum.WEB_COMMERCE,
            moduloEnum.COMMERCE_APP,
            moduloEnum.ORCAMENTO_WEB,
            moduloEnum.ORCAMENTO_APP
          ])
      "
    />

    <GoogleShoppinIntegracoes
      :dados="dadosProps.googleShopping"
      @dataToParent="dataToParent"
      v-if="
        showGoogleShopping &&
          priceGroups &&
          showComponente([moduloEnum.WEB_COMMERCE, moduloEnum.COMMERCE_APP])
      "
    />

    <FacebookIntegracoes
      :dados="dadosProps.facebook"
      @dataToParent="dataToParent"
      v-if="
        showFacebookShopping &&
          priceGroups &&
          showComponente([moduloEnum.WEB_COMMERCE, moduloEnum.COMMERCE_APP])
      "
    />

    <WhatsappIntegracoes
      :dados="dadosProps.whatsapp"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.PORTFOLIO_WEB,
          moduloEnum.ORCAMENTO_WEB,
          moduloEnum.ORCAMENTO_APP,
          moduloEnum.PAINEL_CLIENTE,
          moduloEnum.VENDAMAIS_WEB,
          moduloEnum.VENDAMAIS_APP,
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <ApiKeysIntegracoes
      :dados="dadosProps.apiKeys"
      @dataToParent="dataToParent"
      v-if="
        showComponente([
          moduloEnum.WEB_COMMERCE,
          moduloEnum.COMMERCE_APP
        ])
      "
    />

    <div class="button">
      <FormButton
        text="Salvar"
        type="primario"
        :loading="loading"
        :disabled="loading"
        @click="salvarConfiguracoes"
      />
    </div>
  </div>
</template>

<script>
import EmailIntegracaos from './email/EmailIntegracoes'
import ErpIntegracoes from './erp/ErpIntegracoes'
import GoogleAnalyticsIntegracoes from './google/GoogleAnalyticsIntegracoes'
import SmsIntegracoes from './sms/SmsIntegracoes'
import BlipIntegracoesVue from './blip/BlipIntegracoes'
import RdStationIntegracoes from './rdstation/RdStationIntegracoes'
import GoogleShoppinIntegracoes from './google/GoogleShoppinIntegracoes'
import FacebookIntegracoes from './facebook/FacebookIntegracoes'
import WhatsappIntegracoes from './whatsapp/WhatsappIntegracoes'
import ApiKeysIntegracoes from './api-keys/ApiKeysIntegracoes'
import MarketingModules from '@/components/MarketingModules'
import FormButton from '@/components/form/FormButton'
import { GET_FORNECEDOR_DATA } from '@/store/actions/fornecedor'
import { GRUPO_PRECO_LIST } from '@/store/actions/grupoPreco'
import {
  GET_CONFIG_EMAIL,
  GET_INTEGRATIONS,
  GET_DOMAIN,
  REMOVE_OAUTH,
  UPDATE_INTEGRATIONS
} from '@/store/actions/integracoes'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import * as _ from 'lodash'

export default {
  components: {
    EmailIntegracaos,
    ErpIntegracoes,
    GoogleAnalyticsIntegracoes,
    SmsIntegracoes,
    BlipIntegracoesVue,
    RdStationIntegracoes,
    GoogleShoppinIntegracoes,
    FacebookIntegracoes,
    WhatsappIntegracoes,
    ApiKeysIntegracoes,
    MarketingModules,
    FormButton
  },
  data() {
    return {
      areavenda: {
        idareavendafornecedor: null,
        idregiaoerp: null
      },
      configuracao: {
        trackingGoogleAnalytics: null
      },
      chatBot: {
        buttonTitle: '',
        buttonColor: 'red',
        appKey: '',
        app: true,
        web: true
      },
      seo: {
        slug: ''
      },
      colorPickerActive: false,
      elasticmail: {
        apiKey: '',
        accountId: ''
      },
      smtp: {
        host: '',
        port: '',
        user: '',
        password: '',
        sender: '',
        senderName: '',
        secure: true
      },
      mailService: 'ELASTIC_MAIL',
      fornecedor: {
        iderp: null
      },
      sms: {
        user: '',
        password: '',
        providor: 'zenvia'
      },
      whatsapp: {
        token: '',
        remetenteId: '',
        service: 'ZENVIA_WHATSAPP',
        notificationEmail: ''
      },
      rdstation: null,
      priceGroups: [],
      googleShopping: {
        grupoPreco: '',
        marcaPadrao: ''
      },
      facebookShopping: {
        grupoPreco: '',
        marcaPadrao: '',
        generoPadrao: ''
      },
      apiKeys: [],
      dominio: '',
      modulosForm: [],
      moduloEnum: ModulesEnum,
      dadosProps: {
        erp: null,
        email: null,
        rdStation: null,
        googleAnalytics: null,
        sms: null,
        blip: null,
        googleShopping: null,
        facebook: null,
        whatsapp: null,
        apiKeys: null
      },
      loading: false
    }
  },
  async created() {
    this.obtemDadosEmail()
    this.obtemDadosFornecedor()
    this.obtemDadosConfig()
    this.getPriceGroups()
    this.obtemDadosDominio()
    this.modulosForm = [ModulesEnum.TODOS]
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    showFacebookShopping() {
      return _.get(this.fornecedor, 'tag', '').includes(
        'uses-facebook-shopping'
      )
    },
    showGoogleShopping() {
      return _.get(this.fornecedor, 'tag', '').includes('uses-google-shopping')
    }
  },
  methods: {
    obtemDadosFornecedor() {
      const mensagemErro =
        'Não foi possível obter os dados do fornecedor. Ocorreu um erro no servidor deste aplicativo'

      this.$store
        .dispatch(GET_FORNECEDOR_DATA)
        .then(fornecedor => {
          if (fornecedor && fornecedor.idfornecedor) {
            this.fornecedor = fornecedor

            if (fornecedor && fornecedor.regioes[0]) {
              const regiao = fornecedor.regioes[0]
              this.areavenda = {
                idareavendafornecedor: regiao.idareavendafornecedor,
                idregiaoerp: regiao.id_regiao_erp
              }

              this.dadosProps.erp = this.dadosFormatados('erp')
            }
          } else {
            this.$vueOnToast.pop('error', mensagemErro)
          }
        })
        .catch(() => {
          this.$vueOnToast.pop('error', mensagemErro)
        })
    },
    obtemDadosEmail() {
      this.$store.dispatch(GET_CONFIG_EMAIL).then(config => {
        if (config) {
          if (config.service == 'SMTP') {
            this.mailService = 'SMTP'
            this.smtp = {
              ...this.smtp,
              ...config,
              secure: `${config.secure}` == 'true'
            }
          } else {
            this.mailService = 'ELASTIC_MAIL'
            this.elasticmail = {
              ...this.elasticmail,
              ...config
            }
          }

          this.dadosProps.email = this.dadosFormatados('email')
        }
      })
    },
    obtemDadosConfig() {
      this.$store
        .dispatch(GET_INTEGRATIONS)
        .then((configuracao) => {
          const config = _.get(configuracao, '[0].config.configuracao', null)

          if (config)
            this.configuracao = Object.assign(this.configuracao, config)
          this.dadosProps.googleAnalytics = this.dadosFormatados(
            'googleAnalytics'
          )

          this.normalizarDadosChatBot(configuracao)
          this.normalizarDadosSms(configuracao)
          this.normalizeDadosCrm(configuracao)
          this.normalizarDadosSeo(configuracao)
          this.normalizarDadosShopping(configuracao)
          this.normalizarDadosWhatsapp(configuracao)
          this.normalizarDadosApiKeys(configuracao)
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Não foi possível obter as configurações do fornecedor. Ocorreu um erro no servidor deste aplicativo'
          )
        })
    },
    getPriceGroups() {
      this.$store
        .dispatch(GRUPO_PRECO_LIST, {
          idfornecedor: this.getFornecedorId
        })
        .then(grupos => {
          this.priceGroups = grupos
        })
    },
    obtemDadosDominio() {
      this.$store
        .dispatch(GET_DOMAIN)
        .then(configuracao => {
          this.dominio = _.get(configuracao, '0.config.dominio', '')
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Não foi possível obter os dados da configuração do domínio. Ocorreu um erro no servidor'
          )
        })
    },
    normalizarDadosChatBot(configuracao) {
      const chatBot = _.get(configuracao, '[0].chatBot', null)
      if (chatBot) {
        this.chatBot = Object.assign(this.chatBot, chatBot)
        this.chatBot.app = chatBot.app === 'true' ? true : false
        this.chatBot.web = chatBot.web === 'true' ? true : false
        this.dadosProps.blip = this.dadosFormatados('blip')
      }
    },
    normalizarDadosSms(configuracao) {
      const sms = _.get(configuracao, '[0].sms', null)

      if (sms && sms.providor) {
        this.sms.providor = sms.providor

        if (sms.authorization) {
          let authorization = atob(sms.authorization)
          authorization = authorization.split(':')
          this.sms.user = authorization[0]
          this.sms.password = authorization[1]
        }

        this.dadosProps.sms = this.dadosFormatados('sms')
      }
    },
    normalizeDadosCrm(configuracao) {
      const rdstation = _.get(configuracao, '[0].rdstation', null)
      if (rdstation) {
        this.rdstation = {
          ...rdstation
        }
        this.dadosProps.rdStation = this.dadosFormatados('rdStation')
      }
    },
    normalizarDadosSeo(configuracao) {
      const seo = _.get(configuracao, '[0].seo', null)
      if (seo) {
        this.seo = Object.assign(this.seo, seo)
      }
    },
    normalizarDadosShopping(configuracao) {
      const googleShopping = _.get(configuracao, '[0].googleShopping', null)
      this.googleShopping = Object.assign(this.googleShopping, googleShopping)

      const facebookShopping = _.get(configuracao, '[0].facebookShopping', null)

      this.facebookShopping = Object.assign(
        this.facebookShopping,
        facebookShopping
      )
    },
    normalizarDadosWhatsapp(configuracao) {
      const whatsapp = _.get(configuracao, '[0].whatsapp', null)

      this.whatsapp = {
        ...whatsapp,
        service: whatsapp.service || 'ZENVIA_WHATSAPP'
      }

      this.dadosProps.whatsapp = this.dadosFormatados('whatsapp')
    },
    normalizarDadosApiKeys(configuracao) {
      const apiKeys = _.get(configuracao, '[0].apiKeys', [])

      this.apiKeys = apiKeys
      this.dadosProps.apiKeys = this.dadosFormatados(
        'apiKeys'
      )
    },
    showComponente(modulos = []) {
      if (modulos && modulos.length > 0) {
        return this.moduloFornecedorAtivo(modulos)
      } else {
        return false
      }
    },
    removeOauth(service, label) {
      this.$swal
        .fire({
          title: 'Desvincular conta',
          text: `Deseja realmente desvincular conta da "${label}"?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(async result => {
          if (result.value) {
            this.$store
              .dispatch(REMOVE_OAUTH, service)
              .then(() => {
                this.$vueOnToast.pop(
                  'success',
                  'Conta desvinculada com sucesso!'
                )

                this.obtemDadosConfig()
              })
              .catch(err => {
                const msg = _.get(
                  err,
                  'responseJSON.message',
                  'Falha ao desvincular conta'
                )

                this.$vueOnToast.pop('error', msg)
              })
          }
        })
    },
    dadosFormatados(tipo) {
      const acao = {
        erp: () => {
          return {
            fornecedor: this.fornecedor,
            areavenda: this.areavenda
          }
        },

        email: () => {
          return {
            mailService: this.mailService,
            smtp: this.smtp,
            elasticmail: this.elasticmail
          }
        },

        rdStation: () => {
          return {
            rdstation: this.rdstation
          }
        },

        googleAnalytics: () => {
          return {
            configuracao: this.configuracao
          }
        },

        sms: () => {
          return {
            sms: this.sms
          }
        },

        blip: () => {
          return {
            chatBot: this.chatBot
          }
        },

        googleShopping: () => {
          return {
            googleShopping: this.googleShopping,
            priceGroups: this.priceGroups,
            dominio: this.dominio,
            seo: this.seo
          }
        },

        facebook: () => {
          return {
            facebookShopping: this.facebookShopping,
            priceGroups: this.priceGroups,
            dominio: this.dominio,
            seo: this.seo
          }
        },

        whatsapp: () => {
          return {
            whatsapp: this.whatsapp
          }
        },

        apiKeys: () => {
          return this.apiKeys
        }
      }

      return acao[tipo]()
    },
    dataToParent(dado) {
      const { evento, dados } = dado
      this[evento] = dados
    },
    getData() {
      const { user, password } = this.sms
      const authorization =
        user && password ? btoa(`${user.trim()}:${password.trim()}`) : ''
      const web = String(_.get(this.chatBot, 'web', 'false'))
      const app = String(_.get(this.chatBot, 'app', 'false'))

      this.elasticmail.accountId = ('' + this.elasticmail.accountId).trim()
      this.elasticmail.apiKey = ('' + this.elasticmail.apiKey).trim()
      const whatsapp =
        this.whatsapp.service === 'ZENVIA_WHATSAPP'
          ? {
              service: this.whatsapp.service,
              token: this.whatsapp.token,
              remetenteId: this.whatsapp.remetenteId,
              notificationEmail: this.whatsapp.notificationEmail
            }
          : {
              service: this.whatsapp.service,
              token: this.whatsapp.token
            }

      const novasApiKeys = 
        this.apiKeys
        .filter((dados) => Object.keys(dados).includes('token'))
        .map((api) => {
          return {
            token: api.token,
            token_mascarado: api.token_mascarado,
            descricao: api.descricao
          }
        }) || []

      return {
        areavenda: this.areavenda,
        chatBot: { ...this.chatBot, ...{ web: web, app: app } },
        config: this.configuracao,
        supplier: this.fornecedor,
        sms: {
          providor: this.sms.providor,
          authorization
        },
        email: {
          ...this.elasticmail,
          ...this.smtp,
          service: this.mailService
        },
        facebookShopping: {
          ...this.facebookShopping
        },
        googleShopping: {
          ...this.googleShopping
        },
        whatsapp,
        apiKeys: novasApiKeys
      }
    },
    salvarConfiguracoes() {
      const data = this.getData()
      delete data.apiKeys

      this.loading = true

      this.$store
        .dispatch(UPDATE_INTEGRATIONS, data)
        .then(result => {
          const msg = _.get(
            result,
            'responseJSON.Mensagem',
            'Dados de integração atualizados.'
          )

          this.loading = false
          this.apiKeys = []
          this.obtemDadosConfig()
          this.$vueOnToast.pop('success', msg)
        })
        .catch(err => {
          const msgPadrao =
            'Não foi possível atualizar os dados de integração. Ocorreu um erro no servidor deste aplicativo'
          const msg = _.get(err, 'responseJSON.Mensagem', msgPadrao)

          this.loading = false
          this.$vueOnToast.pop('error', msg)
        })
    }
  },
  watch: {
    googleShopping: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.dadosProps.googleShopping = this.dadosFormatados(
            'googleShopping'
          )
        }
      }
    },
    facebookShopping: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.dadosProps.facebook = this.dadosFormatados('facebook')
        }
      }
    },
    priceGroups: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.dadosProps.facebook = this.dadosFormatados('facebook')
          this.dadosProps.googleShopping = this.dadosFormatados(
            'googleShopping'
          )
        }
      }
    },
    dominio: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.dadosProps.facebook = this.dadosFormatados('facebook')
          this.dadosProps.googleShopping = this.dadosFormatados(
            'googleShopping'
          )
        }
      }
    },
    seo: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.dadosProps.facebook = this.dadosFormatados('facebook')
          this.dadosProps.googleShopping = this.dadosFormatados(
            'googleShopping'
          )
        }
      }
    }
  }
}
</script>

<style scoped>
.button {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
}

.button button {
  color: #fff;
}
</style>
