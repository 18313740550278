<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-if="apiKeys.length"
        v-model="dialog"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Token gerado:
            <b style="padding-left: 10px">{{ descricaoApiKey }}</b>
          </v-card-title>
          <v-card-text>
            <div class="message">
              <i
                class="fas fa-exclamation-circle"
                style="margin-right: 5px"
              ></i>
              Atenção, guarde este token com segurança. Após fechar/recarregar
              esta página, não será mais possível visualizá-lo por completo.
            </div>

            <div class="copiar-token">
              <FormField
                label="Seu token"
                placeholder="Seu token"
                style="width: 90%; height: 40px"
                :value="pegarToken"
              />

              <i
                class="fas fa-copy"
                style="width: 5%"
                @click="copiarToken(pegarToken)"
              />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <Panel lineColor="var(--primary-color)" class="panel">
      <div class="container-panel">
        <v-row no-gutters>
          <h4 class="text-start">API Keys</h4>
          <FormButton
            text="Nova API Key"
            type="primario"
            class="ml-auto"
            @click="onNew"
          />
        </v-row>
        <LineDivider />
        <div style="margin-bottom: 20px">
          <ul class="formulario">
            <li
              class="formulario-item"
              v-for="(conteudo, index) in apiKeys"
              :key="conteudo.idapikeys"
            >
              <FormField
                label="Token"
                placeholder="Seu token de acesso"
                class="form-field"
                :readonly="true"
                v-model="conteudo.token_mascarado"
              />

              <FormField
                label="Descrição da sua API Key"
                placeholder="Sua descrição"
                class="form-field"
                :readonly="true"
                v-model="conteudo.descricao"
              />

              <FormField
                label="Data de criação"
                placeholder="Data de criação"
                class="form-field"
                :readonly="true"
                :value="dataApiKey(conteudo.data_criacao)"
              >
                <template v-slot:append>
                  <slot name="append">
                    <template>
                      <v-icon>fas fa-calendar-alt</v-icon>
                    </template>
                  </slot>
                </template>
              </FormField>

              <FormField
                label="Data de utilização"
                placeholder="Data de utilização"
                class="form-field"
                :readonly="true"
                :value="dataApiKey(conteudo.data_utilizacao)"
              >
                <template v-slot:append>
                  <slot name="append">
                    <template>
                      <v-icon>fas fa-calendar-alt</v-icon>
                    </template>
                  </slot>
                </template>
              </FormField>

              <FormButton
                text="Excluir"
                type="error"
                class="form-button"
                afterIcon="fas fa-trash"
                :loading="loadingRow[index]"
                :disabled="loading"
                @click="
                  excluirApiKey(conteudo.idapikeys, conteudo.descricao, index)
                "
              />
            </li>
          </ul>
        </div>
      </div>
    </Panel>
    <ApiKeysIntegracoesForm
      v-if="showFormApiKey"
      @save="onSave"
      @close="onClose"
    />
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import ApiKeysIntegracoesForm from './ApiKeysIntegracoesForm'
import { DELETE_API_KEYS, INSERT_API_KEY } from '@/store/actions/integracoes'
import * as _ from 'lodash'
import moment from 'moment-timezone'

moment.locale('pt-br')

export default {
  components: {
    Panel,
    LineDivider,
    FormField,
    FormButton,
    ApiKeysIntegracoesForm
  },
  props: {
    dados: {
      type: Array,
      default: function() {
        return []
      },
      required: false
    }
  },
  emits: ['dataToParent'],
  data() {
    return {
      descricao: '',
      dialog: false,
      apiKeys: [],
      showFormApiKey: false,
      loadingRow: {},
      loading: false,
    }
  },
  computed: {
    apiKeyAtual() {
      return this.apiKeys.slice(-1)[0]
    },
    pegarToken() {
      return this.apiKeyAtual?.token
    },
    descricaoApiKey() {
      return this.apiKeyAtual?.descricao
    }
  },
  methods: {
    onNew() {
      this.showFormApiKey = true
    },
    dataApiKey(data) {
      return data
        ? String(
            moment(data)
              .tz('America/Sao_Paulo')
              .format('DD/MM/YYYY HH:mm:ss')
          )
        : ''
    },
    excluirApiKey(idapikey, descricao, index) {
      this.$swal
        .fire({
          title: `Deseja realmente remover essa api key: '${descricao}'?`,
          text: 'Essa ação é irreversível',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(async result => {
          if (result.value) {
            this.loading = true
            this.loadingRow = {
              ...this.loadingRow,
              [index]: true
            }

            this.$store
              .dispatch(DELETE_API_KEYS, idapikey)
              .then(api => {
                if (api.status === 404)
                  return this.$vueOnToast.pop('warning', api.message)

                  this.apiKeys.splice(index, 1)
                  this.loading = false
                  this.loadingRow = {
                    ...this.loadingRow,
                    [index]: false
                  }
                  this.$vueOnToast.pop('success', api.message)
                })
                .catch(error => {
                  this.$vueOnToast.pop('error', error.message)
                  this.loading = false
                  this.loadingRow = {
                    ...this.loadingRow,
                    [index]: false
                  }
                })
            } else {
              this.apiKeys.splice(index, 1)
              this.loading = false
              this.loadingRow = {
                ...this.loadingRow,
                [index]: false
              }
            }
        })
    },
    async copiarToken(token) {
      try {
        await navigator.clipboard.writeText(token)
        this.$vueOnToast.pop('success', 'Token copiado com sucesso')
        this.dialog = false
      } catch (error) {
        this.$vueOnToast.pop('error', 'Erro ao copiar token')
      }
    },
    onSave(value) {
      this.$store
        .dispatch(INSERT_API_KEY, value)
        .then(result => {
          let idapikey = ''
          if (result && result[0].idapikeys) {
            idapikey = result[0].idapikeys
          } else {
            const msgPadrao =
              'Não foi possivel inserir a API Key. Ocorreu um erro no servidor deste aplicativo'
            const msg = _.get(result, 'responseJSON.Mensagem', msgPadrao)
            this.$vueOnToast.pop('error', msg)
            return
          }
          this.apiKeys.push({ ...value, idapikeys: idapikey })
          this.showFormApiKey = false
          this.dialog = true
          this.$nextTick(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            })
          })
          this.$vueOnToast.pop('success', 'API Key cadastrada com sucesso.')
        })
        .catch(err => {
          const msgPadrao =
            'Não foi possivel inserir a API Key. Ocorreu um erro no servidor deste aplicativo'
          const msg = _.get(err, 'responseJSON.Mensagem', msgPadrao)
          this.$vueOnToast.pop('error', msg)
        })
    },
    onClose() {
      this.showFormApiKey = false
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.apiKeys = newVal
        }
      }
    }
  }
}
</script>

<style scoped>
ul {
  width: 100%;
  padding-left: 0;
}

.panel {
  margin-top: 30px;
}

.panel .container-panel {
  padding: 0;
}

.formulario {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}

.formulario-item {
  display: contents;
}

.form-field {
  width: 100%;
  height: 40px;
}

.form-button {
  align-self: end;
  margin-bottom: 2px;
}

.message {
  width: 100%;
  padding: 10px;
  background-color: #fb8c00;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 25px;
  font-size: 14px;
}

.copiar-token {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.copiar-token i {
  font-size: 25px;
  cursor: pointer;
}

.copiar-token i:hover {
  font-size: 25px;
  cursor: pointer;
}

@media (max-width: 1310px) {
  .formulario {
    grid-template-columns: 1fr 1fr;
  }

  .form-field:nth-child(1),
  .form-field:nth-child(2) {
    grid-column: span 2;
  }

  .form-field:nth-child(3),
  .form-field:nth-child(4),
  .form-field:nth-child(5),
  .form-button {
    grid-column: span 2;
  }
}
</style>
