<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :routeback="routeback"
    overlay-not-back
  >
    <FormField
      label="Descrição da sua API Key"
      placeholder="Descrição"
      v-model="form.descricao"
      :validation="$v.form.descricao"
    />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { required } from 'vuelidate/lib/validators'
import crypto from 'crypto'

export default {
  name: 'ApiKeysIntegracoesForm',
  components: {
    SidePopup,
    FormField,
    FormButton
  },
  validations: {
    form: {
      descricao: { required }
    }
  },
  data: () => ({
    form: {
      descricao: ''
    }
  }),
  computed: {
    title() {
      return 'Nova API Key'
    },
    routeback() {
      return `/fornecedor/configuracao/integracoes`
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const data = new Date()
      const gerarTokenRandom = crypto.randomBytes(32).toString('hex')
      const tokenOculto =
        gerarTokenRandom.slice(0, 16) +
        gerarTokenRandom.slice(16).replace(/./g, '*')
      const payload = {
        token: gerarTokenRandom,
        token_mascarado: tokenOculto,
        descricao: this.form.descricao,
        data_criacao: data,
        data_utilizacao: ''
      }
      this.$emit('save', payload)
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>
